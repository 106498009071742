<template>
  <div>
    <div v-if="user.role == 'PESERTA'">
      <dashboard-peserta></dashboard-peserta>
    </div>
    <div v-else-if="user.role == 'PEMATERI'">
      <dashboard-peserta></dashboard-peserta>
    </div>
    <div v-else>
      <v-card flat>
        <v-card-text class="">
          Selamat datang {{ user.nama }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import DashboardPeserta from './DashboardPeserta.vue'
import DashboardPemateri from './DashboardPemateri.vue'

export default {
  components: {
    DashboardPeserta,
    DashboardPemateri,
  },
  data: () => ({
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  methods: {
  },
}
</script>
