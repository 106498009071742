<template>
    <div>
        <h3>Dashboard</h3>
        <v-card class="mt-2" max-width="400">
            <v-img height="200" :src="require('@/assets/images/avatars/user.jpg')"></v-img>
            <v-card-text>
                <h3 class="text-center my-2">{{ user.email }}</h3>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{{ dataUser.nama }}</td>
                            </tr>
                            <tr>
                                <td>NIP</td>
                                <td>:</td>
                                <td>{{ dataUser.nip }}</td>
                            </tr>
                            <tr>
                                <td>NO HP</td>
                                <td>:</td>
                                <td>{{ dataUser.hp }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-btn small color="primary" @click="editData()">
                    Edit
                </v-btn>
            </v-card-actions>
        </v-card>
        <!-- dialog -->
        <v-layout row justify-center>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Profil</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field class="mb-2" label="Nama:" outlined v-model="form.edit.nama"
                                        required dense hide-details=""></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field class="mb-2" label="NIP:" outlined v-model="form.edit.nip"
                                        required dense hide-details=""></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field class="mb-2" label="NO HP:" outlined v-model="form.edit.hp"
                                        required dense hide-details=""></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn small color="primary" @click="save">Simpan</v-btn>
                        <v-btn small color="warning" @click="form.dialog = false">Keluar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </div>
</template>

<script>
import axios from 'axios'
// import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'
import { mdiEye, mdiPencil, mdiMagnify } from '@mdi/js'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

export default {
    components: {
        // UploadDokumenRegistrasi,
    },
    data: () => ({
        dataUser: {},
        icons: {
            mdiEye,
            mdiPencil,
            mdiMagnify
        },
        form: {
            dialog: false,
            default: {
                nama: '',
                nip: '',
                hp: '',
            },
            edit: {
                nama: '',
                nip: '',
                hp: '',
            },
        }
    }),
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getUser()
    },
    methods: {
        editData() {
            this.form.edit.nama = this.dataUser.nama
            this.form.edit.nip = this.dataUser.nip
            this.form.edit.hp = this.dataUser.hp
            this.form.edit.email = this.dataUser.email
            this.form.dialog = true
        },
        // getInstitusiPendidikan() {
        //     const headers = this.headers
        //     axios
        //         .get(`${apiRoot}/api/RegisterLanjutan/getInstitusiPendidikans`, { headers })
        //         .then(response => {
        //             if (response.data.code === 200) {
        //                 this.institusi_pendidikans = response.data.datas
        //             } else {
        //                 alert(response.data.message)
        //             }
        //         })
        //         .catch(error => {
        //             alert(error)
        //         })
        // },
        getUser() {
            const fmData = new FormData()
            fmData.append('user_id', this.user.id)
            axiosPostAuth('api/User/getUser', fmData)
                .then(response => {
                    this.dataUser = Object.assign({}, response.data)
                })
                .catch(error => {
                    alert(error)
                })
        },
        save() {
            const fmData = new FormData()
            fmData.append('nama', this.form.edit.nama)
            fmData.append('nip', this.form.edit.nip)
            fmData.append('hp', this.form.edit.hp)
            axiosPostAuth('api/User/updateUserSelf', fmData)
                .then(response => {
                    this.getUser()
                    this.form.dialog = false
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>